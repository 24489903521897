$body-color: #4d4d4d;

$grey-bg: #f5f5f7;
$grey-light: #DEDEDF;
$grey-medium: #A6A6A7;
$grey-dark: #333333;
$grey-nav: #808080;

$brand-primary-light: #EAF4FD; // blue
$brand-primary-medium: #D8E6F4;
$brand-primary-dark: #478DEE; // blue
$brand-primary-darker: #1C4C96;
$brand-gradient-first: #1C4C96; // old 406BAD
$brand-gradient-second: #4488E6;
$brand-gradient-highlight: #6491CC;

$brand-secondary-dark: #ff6e59; // orange

$box-shadow: #e0e0e0;
