@import '_colors';

@mixin boxShadow {
    box-shadow: 0px 2px 20px $box-shadow;
}

@mixin topBorder($borderColor:$grey-dark) {
    border-top: 4px solid $borderColor !important;
}

@mixin boxPadding($topBottom:2rem, $leftRight:4rem) {
    padding: $topBottom $leftRight;
}

@mixin backgroundGradient($first:$brand-gradient-first, $second:$brand-gradient-second) {
    background: $first;
    background: linear-gradient(135deg, $first 0%, $second 100%);
    color: white;
}

@mixin hoverZoom() {
    transition: all 300ms;
    &:hover {
        border-color: $brand-primary-dark !important;
        cursor: pointer;
        transform: scale(1.02);
        box-shadow: $grey-dark 1px 1px 15px;
    }
}

@mixin btnSolid($color, $background, $hoverColor, $hoverBackground) {
    color: $color;
    background: $background;
    &:hover {
        color: $hoverColor;
        background: $hoverBackground;
    }
}

@mixin btnOutline($color, $hoverColor:white) {
    color: $color;
    background: transparent;
    border: 1px solid $color;
    &:hover {
        color: $hoverColor;
        background: $color;
    }
}
