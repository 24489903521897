#content {
    .pricing-container {
        margin-bottom: 2rem;
        .boxes {
            padding: 0;
            .box {
                margin-bottom: 2rem;
                @include respond-to('laptop') {
                    flex: auto !important;
                    text-align: center;
                }
                .content {
                    background-color: $brand-primary-light;
                    border-top-color: $brand-primary-dark !important;
                    box-shadow: none;
                    margin: 0 1rem;
                    p {
                        color: $grey-dark;
                        font-weight: 500;
                        line-height: 1.45;
                    }
                    h2 {
                        color: $brand-primary-dark;
                    }
                    hr {
                        border: none;
                        border-top: 1px solid $brand-primary-medium;
                        margin-bottom: 2rem;
                    }
                    .content-header {
                        .coming-soon, .space {
                            font-size: 1.4rem;
                            font-weight: 300;
                            @include respond-to('desktop') {
                                display: block;
                            }
                        }
                        .space {
                            @include respond-to('laptop') {
                                display: none !important;
                            }
                        }
                    }
                    .content-description {
                        height: 6rem;
                        @include respond-to('laptop') {
                            height: unset;
                        }
                    }
                    .price p {
                        font-weight: 700;
                        color: $brand-primary-dark;
                    }
                    .project-scenarios {
                        height: 8rem;
                        @include respond-to('laptop') {
                            height: unset;
                        }
                    }
                    .project-benefits {
                        height: 15rem;
                        @include respond-to('laptop') {
                            height: unset;
                        }
                    }
                    .cta {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-bottom: 1rem;
                        @include respond-to('laptop') {
                            padding-top: 1rem;
                        }
                    }
                    & .disabled {
                        pointer-events: none;
                    }
                }
            }
        }
        & .full-width-box {
           .box {
            flex: 0 0 100%;
                .content {
                    border-top: 0 !important;
                    text-align: center;
                    h4 {
                        margin-top: 2rem;
                        font-weight: 500;
                    }
                    .icon-lists {
                        display: flex;
                        justify-content: space-around;
                        margin: 4rem 0;
                        @include respond-to ('tablet') {
                            flex-direction: column;
                            margin: 4rem 0 0 0;
                            .icon-list {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                            }
                        }
                    }
                }
           }
        }
        .footnote {
            display: block;
            text-align: center;
            margin-bottom: 3rem;
            font-size: 1.4rem;
            @include respond-to ('tablet') {
                margin-top: 1rem;
            }
        }
    }

    .features-video-container, .home-video-container {
        .container {
            .video-wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                svg {
                    position: relative;
                    color: white;
                    height: 40px;
                    width: auto;
                }
            }
            .graphic {
                position: relative;
                .graphic-wrapper {
                    position: relative;
                    height: 100%;
                    width: 100%;
                    .play-button {
                        display: flex;
                        align-items: center;
                        background-color: #000;
                        color: white;
                        padding: 7px 10px 9px 10px;
                        border-radius: 20px;
                        position: absolute;
                        top: calc(50% - 15px);
                        left: calc(50% - 64px);
                        font-weight: 600;
                        font-size: 16px;
                        box-shadow: 0px 0px 10px rgba(0, 0, 0, .25);
                        svg {
                            position: relative;
                            z-index: 1;
                            color: white;
                            font-size: 16px;
                            border: none;
                            cursor: pointer;
                            border-radius: 5px;
                            width: 2rem;
                            height: 2rem;
                            display: block;
                            margin-right: 6px;
                        }
                        &:hover, &:focus {
                            background-color: $brand-primary-dark;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
