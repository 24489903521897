// fonts
@import url('//fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;700&display=swap');
// required for bootstrap
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

// optional bootstrap
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/input-group";
@import "../../../node_modules/bootstrap/scss/nav";
@import "../../../node_modules/bootstrap/scss/navbar";
@import "../../../node_modules/bootstrap/scss/card";
@import "../../../node_modules/bootstrap/scss/alert";
@import "../../../node_modules/bootstrap/scss/utilities";
// @import "../../../node_modules/bootstrap/scss/tables";

@import "_breakpoints";
@import "_colors";
@import "_mixins";
@import "pages";

html, body {
    font-family: 'Montserrat', sans-serif;
    font-size: 10px;
    margin: 0;
    padding: 0;
    @include respond-to('tablet') {
        font-size: 8px;
    }  
}

h1 {
    font-size: 4.8rem;
    line-height: 1.25;
    margin-bottom: 2rem;
    font-weight: 500;
}

h2 {
    font-size: 3.2rem;
    line-height: 1.25;
    margin-bottom: 1.5rem;
    font-weight: 500;
}

h3 {
    font-size: 2.4rem;
    line-height: 1;
    margin-bottom: 1.5rem;
    font-weight: 400;
}

h4 {
    font-size: 1.8rem;
    line-height: 1;
    font-weight: 400;
}

p {
    font-size: 1.4rem;
    line-height: 2;
    margin-bottom: 2rem;
    color: $body-color;
}

ul {
    padding-left: 0;
    list-style: none;
    font-size: 1.4rem;
    li {
        line-height: 1.75;
    }
}

table {
    font-size: 1.4rem;
}

.form-control {
    font-size: 14px;
    height: 3.6rem;
}
.form-group {
    margin-bottom: 2rem;
    label {
        font-size: 1.2rem;
        text-transform: uppercase;
        color: $grey-medium;
    }
}

::placeholder {
    color: $grey-medium !important;
    font-weight: 300;
    font-style: italic;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}

.no-underline {
    text-decoration: none !important;
}

#content {
    padding-top: 52px;
    @include respond-to('laptop') {
        text-align: center;
    }
    .banner {
        margin-top: -52px;
        padding-top: 52px;
        background-color: $brand-primary-dark;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 500px;
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        .banner-content {
            display: flex;
            flex-direction: row;
            color: white;
            margin-top: 6rem;
            & > div {
                flex-basis: 50%;
                padding: 0 3rem;
                &.full-width {
                    margin-top: -52px;
                    flex-basis: 100%;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                    &.text {
                        p {
                            color: white;
                            font-size: 2.4rem;
                        }
                    }
                }
            }
            .text {
                p {
                    margin-bottom: 4rem;
                    font-size: 1.6rem;
                    color: white;
                    font-weight: 100;
                }
                a.btn {
                    @include btnOutline(white, $brand-primary-darker);
                    font-size: 1.4rem;
                }
            }
            .graphic {
                margin-bottom: 3rem;
            }
            @include respond-to('desktop') {
                .graphic {
                    display: none;
                }
                & > div {
                    flex-basis: 100%;
                }
            }
        }   
    }
    & .home-bg {
        background-image: url('../../../public/img/layout/building-blue-bg.jpg');
        .banner-content {
            .text.full-width {
                p {
                    font-size: 3.8rem;
                    font-weight: 500;
                }
            }
        }
    }
    & .features-bg {
        background-image: url('../../../public/img/layout/computer-blue-bg.png');
        .banner-content {
            .text.full-width {
                p {
                    font-weight: 500;
                }
            }
        }
    }
    & .made-for-deal-flow-bg {
        background-image: url('../../../public/img/layout/building-blue-bg2.jpg');
    }

    .title-header {
        text-align: center;
        margin: 3rem 0 5rem 0;
        p {
            font-size: 1.8rem;
            line-height: 1.5;
        }
    }

    .one-column {
        display: flex;
        padding: 7.5rem 0;
        .text {
            padding: 0 3rem 0 3rem;
        }
        @include respond-to('desktop') {
            padding: 3rem 0;
        }
    }

    .two-column {
        display: flex;
        flex-direction: row;
        padding: 7.5rem 0;
        align-items: center;
        flex-wrap: wrap;
        & > div {
            flex: 0 0 50%;
            &:first-child {
                padding-right: 2.5%
            }
            &:last-child {
                padding-left: 2.5%;
            }
            @include respond-to('desktop') {
                flex: 1 1 100% !important;
                width: 100%;
                padding-left: 0;
                padding-right: 0;
                min-width: 0;
                margin: 2rem auto;
            }
        }
        .text {
            padding: 0 3rem 0 3rem;
        }
        @include respond-to('desktop') {
            padding: 3rem 0;
            flex-direction: column-reverse;
            &.mobile-reverse {
                flex-direction: column;
            }
        }
    }

    table { 
        &.deal-flow {
            thead {
                th {
                    padding: 0 .5em;
                }
                th:nth-child(2) {
                    background: $brand-primary-dark;
                    color: white;
                    padding: 0 1em;
                }
            }
            i, svg {
                font-size: 1.75rem !important;
                width: 1.75rem !important;
                margin-bottom: .25em;
            }
            td:nth-child(2) {
                background: $brand-primary-dark;
                .vispropBlue {
                    color: white;
                }
            }
            .row-header {
                font-size: .1rem;
                td {
                    &:before {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 1px;
                        background: rgba(0,0,0,.1);
                        margin-top: 1rem;
                    }
                }
            }
            @include respond-to('tablet') {
                font-size: 1.1rem;
                margin-left: -15px;
                margin-right: -15px;
                width: calc(100% + 30px) !important;
                thead {
                    th {
                        height: 10rem;
                        padding: 0;
                        line-height: 1.1;
                        span {
                            transform: rotate(90deg);
                            display: block;
                            max-width: 4rem;
                        }
                    }
                    th:nth-child(2) {
                        background: $brand-primary-dark;
                        color: white;
                        padding: 0 .25em;
                    }
                }
                i, svg {
                    font-size: 1.25rem !important;
                    width: 1.25rem !important;
                    margin-bottom: .25em;
                }
                td {
                    padding: 0;
                }
                td:first-child {
                    padding: .5em .5em .5em 0;
                }
            }
        }
    }

    .tiles {
        margin: 5rem 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // align-items: center;
        justify-content: flex-start;
        .tile {
            flex: 1 1 33%;
            max-width: 33%;
            flex-wrap: wrap;
            min-width: 300px;
            margin-bottom: 2rem;
            padding: 2rem 4rem;
            img {
                margin: 0 auto 3rem auto;
                max-width: 80%;
                display: block;
            }
            @include respond-to('desktop') {
                flex: 1 1 50%;
                max-width: 50%;
                min-width: none;
            }
            @include respond-to('tablet') {
                flex: 1 1 100%;
                max-width: 100%;
            }
        }
    }

    .slideshow {
        position: relative;
        // overflow: hidden;
        padding-top: 30%;
        @include respond-to('desktop') {
            padding-top: 40%;
        }
        @include respond-to('laptop') {
            padding-top: 50%;
        }
        @include respond-to('mobile') {
            padding-top: 60%;
        }
        .wrapper {
            height: 100%;
            margin: 0 auto;
        }
        .slide {
            padding-right: 3%;
            position: absolute;
            width: 100%;
            height: auto;
            // top: 50px;
            top: 50px;
            left: 0;
            // left: -25px;
            opacity: 0;
            transition: all 0.75s ease-in-out, opacity 0.5s ease-in-out;
            // transform: rotate(50deg);
            &:nth-child(1) {
                // left: -25px;
                // bottom: -25px;
            }
            &:nth-child(2) {
                // left: 25px;
                // top: 25px;
            }
            &:nth-child(3) {
                // top: -25px;
            }
            &.active {
                top: 0;
                left: 0;
                opacity: 1;
                transform: rotate(0deg);
            }
        }
    }

    .boxes {
        padding: 8rem 0 6rem 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .box {
            flex: 0 0 50%;
            @include respond-to('mobile') {
                flex: 1 1 100%;
            }
            // max-width: 50%;
            min-width: 300px;
            margin-bottom: 4rem;
            .content {
                background: white;
                @include topBorder;
                @include boxShadow;
                padding: 2rem 4rem;
                margin: 0 2rem;
                height: 100%;
                h2 {
                    font-size: 2.8rem;
                    font-weight: 500;
                }
                img {
                    margin-bottom: 1rem;
                }
            }
        }
        &.blue-boxes {
            .box {
                .content {
                    @include topBorder($brand-primary-dark);
                    background-color: $brand-primary-light;
                    padding: 2rem 4rem 5rem 4rem;
                    .views-list {
                        color: $brand-primary-dark;
                        cursor: pointer;
                        font-size: 1.25rem;
                        label {
                            margin-right: .125em;
                            margin-bottom: 0;
                        }
                        span {
                            font-weight: 600;
                            text-decoration: underline;
                            display: inline-block;
                            padding: 0 .5em;
                            margin: 0 .125em;
                            border-right: thin solid $brand-primary-dark;
                            &:last-child {
                                border: none;
                            }
                        }
                    }
                }
            }
        }
        &.threes {
            .box {
                flex: 1 1 33%;
            }
        }
    }


    .bg-grey {
        background: $grey-bg;
    }

    .blue-text {
        color: $brand-primary-dark;
    }

    .vispropBlue {
        color: $brand-primary-dark;
    }

    .grey {
        color: $grey-medium;
    }

    .red {
        color: red;
    }

    .orange {
        color: $brand-secondary-dark;
    }

    .gradient-band {
        @include backgroundGradient;
        text-align: center;
        padding: 6rem 0;
        h2 {
            font-size: 2.8rem;
            font-weight: 400;
            margin-bottom: 2.5rem;
        }
    }

    .form-holder {
        width: 60%;
        @include respond-to('tablet') {
            width: 100%;
        }
        margin: auto;
        padding: 3rem;
        @include boxShadow;
        @include topBorder;
        margin-bottom: 8rem;
    }
}

ul.items {
    list-style: square;
    margin-left: 2rem;
    li {
        margin-bottom: 2.5rem;
        p {
            line-height: 1;
            margin-bottom: 0rem;
        }
        span {
            color: $grey-medium;
            font-style: italic;
        }
    }
}

ul.icon-list {
    max-width: 500px;
    li {
        display: flex;
        flex-direction: row;
        align-items: top;
        margin-bottom: 1rem;
        text-align: left;
        svg {
            margin-top: .5rem;
            color: $brand-primary-dark;
            margin-right: 1rem;
        }
    }
}

.footer {
    text-align: center;
    margin-top: 6rem;
    padding-bottom: 1rem;
    color: $grey-medium;
}

.btn {
    text-align: center;
    border-radius: 2rem;
    border: none;
    // padding: .5rem 2.5rem;
    padding: 1rem 2.5rem;
    font-size: 1.6rem;
    &:hover {
        text-decoration: none;
        cursor: pointer;
    }
    &.pale {
        @include btnSolid($brand-primary-dark, $brand-primary-medium, $brand-primary-medium, $brand-primary-dark);
    }
    &.blue {
        @include btnSolid(white, $brand-primary-dark, $brand-primary-dark, $brand-primary-medium);
    }
    &.darker {
        @include btnSolid(white, $brand-primary-darker, $brand-primary-dark, $brand-primary-medium);   
    }
    @include respond-to('desktop') {
        display: table;
        margin: 0 auto;
    }
}

.modal {
    .modal-dialog {
        position: fixed;
        top: 10vh;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 5000;
        max-width: 800px;
        width: 90%;
        max-height: 90vh;

        .modal-content {
            height: 100%;
            width: 100%;
            position: relative;
            z-index: 200;

            .close-icon {
                position: absolute;
                top: -3rem;
                right: 0;
                z-index: 100;
                font-size: 3rem;
                color: white;
                &:hover {
                    cursor: pointer;
                    color: $brand-primary-dark;
                }
            }
        }
    }

    .modal-backdrop {
        opacity: .6;
        z-index: 4999;
        position: fixed;
        width: 100%;
        height: 100vh;
        left: 0;
        top: 0;
        background-color: black;
    }
}

.modal-open {
    overflow: hidden
}